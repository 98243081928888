@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    button[type='button'] {
        background-color: #4096ff;
        border-color: #4096ff;
        color: #F9F9F9;
    }
}