.dropdown_language .ant-dropdown,
.dropdown_language .ant-dropdown-menu,
.dropdown_language .ant-dropdown-menu-item,
.dropdown_language .ant-dropdown-menu-item-only-child {
    padding: 4px 0 4px 0 !important;
    background-color: transparent !important;
    box-shadow: none;
    cursor: default !important;
}

.ant-image-img-hover-blue:hover img {
    filter: invert(81%) sepia(42%) saturate(5519%) hue-rotate(185deg) brightness(100%) contrast(103%);
    transition: ease-in-out 0.3s;
}

.simple_pagination .ant-pagination-prev,
.simple_pagination .ant-pagination-next {
    display: none;
}

.ant-segmented-group {
    display: flex;
    justify-content: space-between;
}

.ant-input-group-addon {
    padding: 0px !important;
    background-color: #5ca5ff !important;
    border: none !important;
}

.ant-pagination-item a {
    color: white !important;
}

.ant-input-group-addon:hover {
    padding: 0px !important;
    background-color: #1677ff !important;
    border: none !important;
}

.ant-pagination-item-ellipsis {
    color: white !important;
}

.ant-pagination-item-active a {
    color: #4096ff !important;
}

.ant-segmented-item:hover {
    color: #4096ff !important;
}

.ant-input-affix-wrapper {
    background-color: white !important;
}

.ant-input-group-addon .anticon {
    padding: 11px !important;
}

.childern-text-custom-white .ant-result-title,
.childern-text-custom-white .ant-result-subtitle {
    color: white;
}

.ant-popover {
    max-width: none !important;
}

.ant-popover-content {
    margin-left: 25px;
}

.ant-pagination-options-size-changer {
    display: none;
}

.text-2xs {
    font-size: 0.5625rem;
    line-height: 0.75rem;
}

.text-3xs {
    font-size: 0.375rem;
    line-height: 0.50rem;
}

.clip-circle {
    clip-path: circle(60px at center);
}

.rotate {
    animation: rotate 20s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.ant-card-head {
    background-color: #cfcfcf !important;
}

.quill .ql-toolbar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.quill .ql-container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.quill .ql-container,
.quill .ql-toolbar {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.quill .ql-clean,
.quill .ql-expanded .ql-picker-item:nth-child(1),
.quill .ql-expanded .ql-picker-item:nth-child(2) {
    display: none !important;
}

.quill .ql-tooltip {
    top: -8px !important;
    left: 2px !important;
    border-radius: 5px;
}

.ant-switch-override:not(:checked),
.ant-switch-form-override .ant-switch:not(:checked) {
    background-color: #1677ff;
}

.ant-switch-override:hover,
.ant-switch-form-override .ant-switch:hover {
    background-color: #1677ff !important;
}

.ant-form-item {
    margin-bottom: 6px !important;
}

.ant-form-item-explain-error span {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
}

.ant-card-padding-bottom-override .ant-card-body {
    padding-bottom: 8px !important;
}

hr {
    border-color: #b6b6b6;
}

.ant-upload-size-override-bannerpicture .ant-upload .ant-upload .ant-image .ant-image-img,
.ant-upload-size-override-bannerpicture .ant-upload .ant-upload .ant-image,
.ant-upload-size-override-bannerpicture .ant-upload .ant-upload,
.ant-upload-size-override-bannerpicture .ant-upload,
.ant-upload-size-override-bannerpicture {
    width: 500px !important;
    height: 50px !important;
    margin: 0px !important;
}

.ant-upload-size-override-tilepicture .ant-upload .ant-upload .ant-image .ant-image-img,
.ant-upload-size-override-tilepicture .ant-upload .ant-upload .ant-image,
.ant-upload-size-override-tilepicture .ant-upload .ant-upload,
.ant-upload-size-override-tilepicture .ant-upload,
.ant-upload-size-override-tilepicture {
    width: 300px !important;
    height: 125px !important;
    margin: 0px !important;
}

.ant-upload-size-override-sponser .ant-upload .ant-upload .ant-image .ant-image-img,
.ant-upload-size-override-sponser .ant-upload .ant-upload .ant-image,
.ant-upload-size-override-sponser .ant-upload .ant-upload,
.ant-upload-size-override-sponser .ant-upload,
.ant-upload-size-override-sponser {
    width: 100px !important;
    height: 100px !important;
    margin: 0px !important;
}

.ant-upload-size-override-artist .ant-upload .ant-upload .ant-image .ant-image-img,
.ant-upload-size-override-artist .ant-upload .ant-upload .ant-image,
.ant-upload-size-override-artist .ant-upload .ant-upload,
.ant-upload-size-override-artist .ant-upload,
.ant-upload-size-override-artist {
    width: 100px !important;
    height: 100px !important;
    margin: 0px !important;
}

.ant-image-preview-root,
.ant-image-preview-operations-wrapper,
.ant-upload .ant-image-mask {
    display: none !important;
}

.ant-upload .ant-image {
    height: 100%;
}

.ant-upload .ant-image .ant-image-img {
    border-radius: 8px;
}

.ant-modal-footer .ant-btn-primary {
    background-color: #1677ff;
}

.ant-picker-ranges .ant-btn-primary {
    background-color: #1677ff;
}

.ant-popover {
    margin: 25px;
    max-width: 50%;
}

.card-p-sm .ant-card-body {
    padding: 12px;
}

.ticket .ant-card-body {
    border-right: dashed 1px white;
    border-left: dashed 1px white;
    border-radius: 0 !important;
    height: 100%;
    padding: 6px 12px;
}

.hover-text-green-enabled:hover:not(:disabled) svg {
    color: greenyellow;
}

.hover-text-red-disabled:hover:disabled svg {
    color: red;
}

.strike-through {
    position: relative;
    display: inline-block;
    font-weight: 500;
}

.strike-through::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 2px solid red;
    transform: rotate(-9deg);
    transform-origin: center;
}

.sale .ant-tag {
    margin: 0;
}

.copyicon-right .ant-typography-copy {
    position: absolute;
    right: 0;
}

.dot-red .ant-steps-icon-dot,
.tail-red .ant-steps-item-tail::after {
    background-color: red !important;
}

.dot-green .ant-steps-icon-dot,
.tail-green .ant-steps-item-tail::after {
    background-color: greenyellow !important;
}

.event-tile .ant-card-body {
    padding: 6px 12px;
}

.card-p-none .ant-card-body {
    padding: 0px;
}

.depth-shadow {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

.ant-empty-description {
    color: #cfcfcf !important;
}

.override-scrollbar {
    scrollbar-color: #ffffff #00000000;
    scrollbar-width: thin;
}

.ant-card-title-stage .ant-card-head {
    background-color: #1e293b !important;
}

.line-height-lg {
    line-height: 1.75rem;
}

.hover-custom-button-sonar-blue:hover button {
    color: #4096ff;
    border-color: #4096ff;
    background: #ffffff;
}

.custom-ant-list-empty-text .ant-list-empty-text {
    padding-top: 8px;
}

.custom_table_sales tr:last-child p {
    padding-bottom: 4px;
}

.table_background_with_ismailed:has(.ismailed),
.table_background_with_ismailed:has(.ismailed) {
    background-color: #F87171 !important;
}

.cursor-grabbing {
    cursor: grabbing;
}

.book-description>span>span {
    display: block;
}

.rate-bg-32 svg {
    width: 32px;
    height: 32px;
}

.rate-bg-light div[aria-checked="false"] svg path,
.rate-bg-light div[aria-checked="false"] svg {
    color: #cfcfcf77;
    fill: #cfcfcf77;
}

.rate-bg-checked-red div[aria-checked="true"] svg path,
.rate-bg-checked-red div[aria-checked="true"] svg {
    color: #F87171 !important;
    /* Red */
    fill: #F87171 !important;
}

.rate-bg-checked-blue div[aria-checked="true"] svg path,
.rate-bg-checked-blue div[aria-checked="true"] svg {
    color: #60A5FA !important;
    /* Blue */
    fill: #60A5FA !important;
}

.rate-bg-checked-yellow div[aria-checked="true"] svg path,
.rate-bg-checked-yellow div[aria-checked="true"] svg {
    color: #FBBF24 !important;
    /* Yellow */
    fill: #FBBF24 !important;
}

.rate-bg-checked-white div[aria-checked="true"] svg path,
.rate-bg-checked-white div[aria-checked="true"] svg {
    color: #FFFFFF !important;
    /* White */
    fill: #FFFFFF !important;
}

.rate-bg-checked-green div[aria-checked="true"] svg path,
.rate-bg-checked-green div[aria-checked="true"] svg {
    color: #34D399 !important;
    /* Green */
    fill: #34D399 !important;
}

a:hover {
    color: white;
}

.custom-color-placeholder input::placeholder,
.custom-color-placeholder textarea::placeholder {
    color: #00000055;
    font-weight: bold;
}

.ant-input-data-count {
    color: #ffffffaa !important;
}

.card-sessions .ant-card-head {
    background-color: #252526 !important;
    color: white;
    padding: 0px 12px;
}

.card-sessions .ant-card-body {
    background-color: #545454 !important;
    padding: 8px;
}

:focus {
    outline: none !important;
}

.ant-slider-rail,
.ant-slider-rail:focus,
.ant-slider-rail:active,
.ant-slider-rail:disabled {
    background-color: #252526 !important;
}

.ant-slider-mark span,
.ant-progress-text {
    color: white !important;
}

.dropdown_language .ant-dropdown,
.dropdown_language .ant-dropdown-menu,
.dropdown_language .ant-dropdown-menu-item,
.dropdown_language .ant-dropdown-menu-item-only-child {
    padding: 4px 0 4px 0 !important;
    background-color: transparent !important;
    box-shadow: none;
    cursor: default !important;
}

.book_effect {
    box-shadow: 0px 0px 10px 4px rgba(127, 127, 127, 0.69);
}

.Modal-Portal .datepicker {
    bottom: initial;
}

.ant-input-clear-icon {
    transform: scale(1.5);
    padding-right: 4px;
}